<template>
  <div class="group-list-container">
    <div class="group-list-area">
      <div class="list-title">{{ selectTitle }}</div>
      <div class="new-group-btn">
        <a-select
          v-if="isShowModeSelector"
          size="large"
          v-model:value="selectMode"
          style="width: 318px"
        >
          <a-select-option
            v-show="selectOptions.length > 1"
            v-for="item in selectOptions"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </div>
      <div class="group-list">
        <ULifeCareSerialNumber
          v-if="selectMode === 'com.viwaveulife.healthcare.mobile'"
        />
        <StationHomeSerialNumber
          v-else-if="selectMode === 'com.viwaveulife.healthstation.home'"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref, onMounted } from 'vue'
  import { useI18n } from 'vue-i18n'
  import ULifeCareSerialNumber from './components/ULifeCareSerialNumber.vue'
  import StationHomeSerialNumber from './components/StationHomeSerialNumber.vue'
  import { useStore } from 'vuex'

  // Initialize composables
  const { t } = useI18n()
  const { locale } = useI18n()
  const store = useStore()

  const appPlan = computed(() => store.state.serialNumber.appPlan)
  const getAppPlan = () => store.dispatch('serialNumber/getAppPlan')
  const serialNumber = computed(() => store.state.serialNumber.serialNumber)
  const getSetSerialNumber = () =>
    store.dispatch('serialNumber/getSetSerialNumber')

  const selectOptions = ref([])
  // const selectOptions = ref([
  //   {
  //     label: 'modeUlifeCloud',
  //     value: modeUlifeCloud,
  //   },
  //   {
  //     label: 'modeStationHome',
  //     value: modeStationHome,
  //   },
  // ])
  const selectMode = ref()

  onMounted(async () => {
    await getAppPlan()
    await getSetSerialNumber()

    appPlan.value.forEach((item) => {
      if (isSerialNumberContainPackage(item.package) && item?.en && item?.tw) {
        selectOptions.value.push({
          label: item[locale.value],
          value: item.package,
        })
      }
    })

    if (selectOptions.value.length > 0) {
      selectMode.value = selectOptions.value[0].value
    }
  })

  const isSerialNumberContainPackage = function (packageId) {
    return serialNumber.value.some((item) => item.stationID.includes(packageId))
  }

  const isShowModeSelector = computed(() => selectOptions.value.length > 1)

  const selectTitle = computed(() => {
    // 依據 selectMode.value ，回傳對應的 selectOptions.label
    const target = selectOptions.value.find(
      (item) => item.value === selectMode.value
    )
    return target ? target.label : ''
  })
</script>

<style lang="less" scoped>
  .clear-fix-float() {
    content: '';
    display: block;
    clear: both;
  }

  .group-list-container {
    background-color: #e5e5e5;
    padding-top: 24px;
    padding-bottom: 24px;

    .group-list-area {
      margin-left: 32px;
      margin-right: 32px;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
      border-radius: 8px;

      .list-title {
        margin-top: 25px;
        margin-left: 20px;
        height: 32px;
        width: 400px;
        float: left;
      }

      .new-group-btn {
        height: 32px;
        margin-top: 20px;
        margin-right: 10px;
        margin-bottom: 20px;
        float: right;
      }

      .group-list {
        .clear-fix-float();
      }
    }
  }
</style>
